<template>
	<el-dialog custom-class="protocol" title="机构入驻合作协议" :visible.sync="show" width="1000px" :close-on-click-modal="false" :modal-append-to-body="false" @closed="handleClose">
		<div class="inner">
			<p style="background-color: #ffffff; margin: 0pt">
				<a style="color: #172b4d" href="javascript:;"
					><span style="color: #172b4d; font-family: 宋体; font-size: 21pt; text-decoration: underline">《</span
					><span style="color: #172b4d; font-family: 宋体; font-size: 21pt; text-decoration: underline">人力资源和社会保障部社会保障能力建设中心</span
					><span style="color: #172b4d; font-family: 宋体; font-size: 21pt; text-decoration: underline">机构入驻服务协议》</span></a
				>
			</p>
			<p style="background-color: #ffffff; margin: 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构入驻服务协议</span><br /><span
					style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>欢迎您入驻并使用</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">入驻服务协议</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">为入驻、使用</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人</span
				><span style="font-family: 宋体; font-size: 10.5pt; font-weight: bold">力资源和社会保障部社会保障能力建设</span
				><span style="font-family: 宋体; font-size: 10.5pt; font-weight: bold">中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">入驻服务协议</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">（以下简称「</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">协议</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">」），您应当阅读并遵守《</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人</span
				><span style="font-family: 宋体; font-size: 10.5pt; font-weight: bold">力资源和社会保障部社会保障能力建设</span
				><span style="font-family: 宋体; font-size: 10.5pt; font-weight: bold">中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>机构入驻服务协议》（以下简称「本协议」）。请您务必审慎阅读、充分理解本协议各条款内容，特别是免除或限制责任的相应条款。限制、免责条款可能以加粗形式提示您注意。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>您以任何形式入驻、使用，都将视为您已阅读、理解并同意接受本协议的约束。如果您对本协议的任何条款表示异议，您可以选择不入驻。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">【特别提示】</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">本政策仅适用于</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人</span
				><span style="font-family: 宋体; font-size: 10.5pt; font-weight: bold">力资源和社会保障部社会保障能力建设</span
				><span style="font-family: 宋体; font-size: 10.5pt; font-weight: bold">中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">及其关联公司</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">提供的产品和服务及其延伸的功能，包括网站、</span
				><span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">H</span
				><span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">5</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>、小程序以及随技术发展出现的新形态向您提供的各项产品和服务。如我们提供的某款产品有单独的隐私政策或相应的用户服务协议当中存在特殊约定，则该产品的隐私政策将优先适用；该款产品隐私政策和用户服务协议未涵盖的内容，以本政策内容为准。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">请仔细阅读《</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人</span
				><span style="font-family: 宋体; font-size: 10.5pt; font-weight: bold">力资源和社会保障部社会保障能力建设</span
				><span style="font-family: 宋体; font-size: 10.5pt; font-weight: bold">中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>隐私政策》（尤其是粗体内容）并确定了解我们对您个人信息的处理规则。阅读过程中，如您有任何疑问，可通过《</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人</span
				><span style="font-family: 宋体; font-size: 10.5pt; font-weight: bold">力资源和社会保障部社会保障能力建设</span
				><span style="font-family: 宋体; font-size: 10.5pt; font-weight: bold">中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">隐私政策》中的联系方式咨询我们。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">一、协议范围</span><br /><span
					style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt"
					>1.1 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">本协议是您与</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人</span
				><span style="font-family: 宋体; font-size: 10.5pt">力资源和社会保障部社会保障能力建设</span
				><span style="font-family: 宋体; font-size: 10.5pt">中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">之间关于您入驻、使用</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">所订立的协议。机构是</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人</span
				><span style="font-family: 宋体; font-size: 10.5pt">力资源和社会保障部社会保障能力建设</span
				><span style="font-family: 宋体; font-size: 10.5pt">中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>向公司、单位、社会组织、政府机构等法人或非法人实体（以下将更多的称呼为「您」或「用户」）提供的</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人</span
				><span style="font-family: 宋体; font-size: 10.5pt">力资源和社会保障部社会保障能力建设</span
				><span style="font-family: 宋体; font-size: 10.5pt">中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">入驻渠道，以便您能够以本机构名义使用</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人</span
				><span style="font-family: 宋体; font-size: 10.5pt">力资源和社会保障部社会保障能力建设</span
				><span style="font-family: 宋体; font-size: 10.5pt">中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">提供的各项互联网技术服务。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">1.2 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">入驻完成后，您可以进行以下操作：帐号设置与管理、</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">商品</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">管理</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">、</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">实训管理、订单管理等，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">可对业务进行操作和管理</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">二、帐号入驻流程</span><br /><span
					style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt"
					>2.1 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构入驻时，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">请您使用未与</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人</span
				><span style="font-family: 宋体; font-size: 10.5pt">力资源和社会保障部社会保障能力建设</span
				><span style="font-family: 宋体; font-size: 10.5pt">中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">个人帐号和其他</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">绑定的</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">手机号注册</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">。</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账号激活后，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人</span
				><span style="font-family: 宋体; font-size: 10.5pt">力资源和社会保障部社会保障能力建设</span
				><span style="font-family: 宋体; font-size: 10.5pt">中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">有权根据用户需求和产品需要对</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构入驻时</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">注册和绑定的</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">进行变更。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">2.2 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">您在入驻时需要选择机构类型并填写入驻资料，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人</span
				><span style="font-family: 宋体; font-size: 10.5pt">力资源和社会保障部社会保障能力建设</span
				><span style="font-family: 宋体; font-size: 10.5pt">中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>有权将您选择的机构类型作为入驻资料的审核依据，入驻完成后将无法更改。您应当如实填写和提交帐号入驻资料，并指定</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>负责人一名。入驻审核包括机构资质、帐号基本信息、运营负责人信息登记等信息的审核。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">2.3 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">为向您提供更专业的服务，您同意</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人</span
				><span style="font-family: 宋体; font-size: 10.5pt">力资源和社会保障部社会保障能力建设</span
				><span style="font-family: 宋体; font-size: 10.5pt">中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">对您所提交的入驻资料进行核查、校验和审核，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人</span
				><span style="font-family: 宋体; font-size: 10.5pt">力资源和社会保障部社会保障能力建设</span
				><span style="font-family: 宋体; font-size: 10.5pt">中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">也可能会在法律允</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>许和审核需要的范围内对您提交的资料、信息及相关背景信息、关联信息进行查询，并根据审核情况作出入驻是否成功的判断。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">2.4 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>机构入驻审核仅限于对您提交的资料及信息进行合理、谨慎的形式审查，在</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">的合法权限和合理能力范围内，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>无法实质审查您的实际经营、运营以及推广等行为，并不为此提供任何担保。如您提交给</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>的信息存在不全、无效或虚假，或因您行为导致与其他用户或第三方发生争议的，将由您独立对外承担责任，因此给</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">或第三方造成损害的，您应当依法予以全额赔偿。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">2.5 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>您应遵循入驻流程完成信息登记，并对资料的真实性、合法性、准确性和有效性承担责任。</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>依据您填写和提交的帐号资料，在法律允许的范围内向其他用户展示您的信息。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">2.6 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">您理解并同意，您为入驻</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">所提供的所有信息构成您的真实意思表示，否则</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">有权依照本协议及相关</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">帐号规范对您进行处罚。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">2.7 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">如您从事的行业或在</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>站内所发表、推广的内容需要取得相关法律法规规定的许可或进行备案的，您应当在帐号入驻时，遵循诚实信用原则，自行提交相应的许可或备案证明。否则，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">有权拒绝或随时终止提供</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">相关服务，并依照本协议对违规帐号进行处罚。因此给</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">或第三方造成损害的，您应当依法予以全额赔偿。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">三、机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">使用规则</span><br /><span
					style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold"
					>3.1 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">是</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>为用户提供的多种帐号形式中的一种，因此除遵守本协议外，您还应当遵守《</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">注销协议》、《个人信息保护指引》、《</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">使用规范（试行）》、《</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">隐私政策》等与</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>帐号相关的各项制度规范（以下简称「专项规则」）。本协议内容与上述内容存在冲突的，以本协议为准。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">3.2 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">您在入驻、使用机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>过程中所制作、复制、发布、传播的任何内容都受到本协议及专项规则的约束，包括但不限于您入驻、使用机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>号所产生的机构头像、名称、用户说明等入驻信息或文字、语音、图片、视频、图文等发送、回复和相关链接页面，以及其他使用</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">所产生的内容。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">3.3 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">您理解并同意，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>一直致力于提供文明健康、规范有序、科学和谐的网络环境，您应当遵照</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">已发布的所有帐号相关的使用规则，不得利用机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>直接或间接从事任何违反中国法律法规以及社会公德的行为。您不得利用机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">制作、</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">下</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">载、复制、发布、传播或者转载如下内容，否则</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">有权删除、屏蔽相关内容，并根据违规程度对该</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">采取相应的处理措施。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">3.3.1 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">违反国家法律法规的内容；</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">3.3.2 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">侵害他人名誉权、肖像权、知识产权、商业秘密等合法权利的内容；</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">3.3.3 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">涉及他人隐私、个人信息或资料的内容；</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">3.3.4 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">骚扰信息及垃圾信息或含有任何性或性暗示的内容；</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">3.3.5 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>发布不符合国家相关法律法规规定的广告，包括但不限于发布国家禁止发布的广告内容，未事先经过有关部门的审查但法律法规要求审查的广告内容等。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">3.3.6 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">其他违反法律法规、政策及公序良俗、社会公德或干扰</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">正常运营和侵犯其他用户或第三方合法权益内容的信息。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">四、帐号管理</span><br /><span
					style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold"
					>4.1 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">的所有权归</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">北京</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">所有，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>使用权仅属于初始申请入驻主体。帐号使用权禁止赠与、借用、租用、转让或售卖。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">4.2 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">您在申请入驻时还应指定机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">号负责人一名，并向</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">提供机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">号负责人的真实姓名、手机号。</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">有权对机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">号负责人的身份进行核查。未成年人不得作为</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">负责人。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">4.3 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>号负责人负责对帐号进行日常运营管理，包括但不限于帐号登录、密码找回、安全验证、机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">号事务联系、申请机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">号认证等。机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">号使用过程中可变更负责人，变更流程需按「帐号设置」的提示操作。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">4.4 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">号密码由您自行设定。</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">特别提醒您应妥善保管您的帐号和密码。</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">与您共同负有维护帐号安全的责任。</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>会采取并不断更新技术措施，努力保护您的帐号在服务器端的安全。您需要采取特定措施保护您的帐号安全，包括但不限于妥善保管</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>与密码、安装防病毒木马软件、定期更改密码等措施。当您使用完毕后，应安全退出。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">4.5 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>因您保管不善可能导致帐号被他人使用（包括但不限于遭受盗号、密码失窃）或信息数据泄漏，责任由您自行承担。因您保管不善可能导致帐号被他人使用（包括但不限于遭受盗号、密码失窃）或信息数据泄漏，责任由您自行承担。</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"> </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>您理解并同意，在您未进行投诉或提出帐号申诉等方式明确告知</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>帐号被他人使用或信息数据泄漏等情况并提供相关证明材料前，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">有理由相信该帐号行为是您使用帐号的行为。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">4.6 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">在您怀疑他人在使用您的帐号或密码时，您同意立即通知</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">。如果您当前使用的</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">并不是您初始申请入驻的或者通过</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>提供的其他途径获得的，但您却知悉该帐号当前的密码，您不得用该帐号登录或进行任何操作，并请您在第一时间通知</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">或者该帐号的初始申请入驻主体。如果</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">发现您并非该帐号初始申请入驻主体，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">有权在未经通知的情况下终止您使用该帐号。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">4.7 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">如您违反相关法律法规、本协议以及专项规则的规定，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">有权进行独立判断并随时限制、冻结或终止您对</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>的使用，且根据实际情况决定是否恢复使用。由此给您带来的损失（包括但不限于通信中断，用户资料及相关数据清空等），由您自行承担。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">4.8 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">如果您的</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>被盗、密码遗忘或因其他原因导致无法正常登录，责任由您自行承担。您可以按照</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">的申诉途径进行申诉</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">（</span
				><span style="background-color: #ffffff; color: #171a1d; font-family: 微软雅黑; font-size: 10.5pt">gzyj@wdcloud.cc</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">），但</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">并不承诺您一定能通过申诉找回帐号。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">4.9 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>为了充分利用帐号资源，如果您存在长期未完成入驻审核验证、入驻后未及时进行初次登录使用，或长期未登陆使用</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">等情形，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">有权暂停或终止该帐号的使用。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">五、数据采集、储存和信息披露</span><br /><span
					style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold"
					>5.1 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">信息行使以下权利。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">平台对采集入住机构信息的必要使用场景：</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>当您遵循入驻流程完成机构信息登记时，我们会对您填入的信息进行收集和使用。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>以下信息为了帮助您入驻时绑定机构账户负责人，同时满足相关法律法规的真实身份信息认证要求：</span
				><br /><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">联系人姓名、电话；</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>入驻时您需要提供真实的联系人姓名、联系人电话，机构审核通过后，将为此联系电话开通账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>，用户可登录此账号开展招生、推广等业务，同时也可对账号进行编辑、禁用等操作。</span
				><br /><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">以下信息</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>会依据您填写的资料进行合理、谨慎的形式审查，以保证真实性、合法性、准确性和有效性：</span
				><br /><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">机构名称、地址、社会统一信用代码、营业执照</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">，可用于机构主页展示、</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">机构开展招生等业务</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">使用</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">；</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">是否允许加入人才库</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>，允许后您的学员将加入平台人才库，依据学员在平台的学习记录，测评结果等过程性行为，平台为其推荐匹配的就业岗位；</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">培训职业及工种</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">上传培训</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">教师资格证明</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">可用于</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">组织考评员</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">开展线下实训</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">业务</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">5.2</span
				><span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">&#xa0;</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">不对您在机构中相关数据的删除或储存失败负责、</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>有权根据实际情况自行决定单个用户在机构中数据的最长储存期限，并在服务器上为其分配数据最大存储空间等。您可根据自己的需要自行备份机构中的相关数据。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">5.3 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">如果您停止使用机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">或相关服务被终止或取消，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>可以从服务器上永久地删除您的数据。在服务停止、终止或取消后，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">没有义务向您返还任何数据。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">5.4 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">将按照法律法规的规定、行业规范及《</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>隐私政策》使用您的数据和信息，不向任何无关的第三方披露您入驻和使用帐号时提供的非公开信息以及</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">负责人的个人信息，但您理解并同意，有下列情形之一的除外：</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">5.4.1 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">事先获得了您的明确授权；</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">5.4.2 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">为了维护公众以及</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">的合法利益；</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">5.4.3 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>可能会与第三方合作向您提供相关的网络服务，在此情形下，如果该等第三方同意承担与</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">同等的</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">信息保护责任，则</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>有权将您的信息提供给该等第三方，无需获得您的的另行同意；</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">5.4.4 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">相关法律法规或主管部门、司法机关的要求。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">六、风险及免责</span><br /><span
					style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt"
					>6.1 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">您理解并同意：为了向您提供有效的服务，机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">的使用会利用您终端设备的处理器和带宽等资源。机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>使用过程中可能产生数据流量的费用，您需自行向运营商了解相关资费信息，并自行承担相关费用。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">6.2 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">您在使用机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">号时，须自行承担如下</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">不可掌控的风险内容，包括但不限于：</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">6.2.1 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>由于受到计算机病毒、木马或其他恶意程序、黑客攻击的破坏等不可抗拒因素可能引起的信息丢失、泄漏等风险；</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">6.2.2 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">您或</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">的电脑软件、系统、硬件和通信线路出现故障；</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">6.2.3 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">您操作不当或通过非</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">授权的方式使用机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">；</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">6.2.4 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">由于网络信号不稳定等原因，所引起的机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">登录失败、资料同步不完整、页面打开速度慢等风险；</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">6.2.5 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">由于法律法规和相关政策的改变，导致服务停止或中断；</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">6.2.6 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">无法控制或合理预见的其他情形。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold">6.3 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">您理解并同意，您通过机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>发表的内容可能会被其他用户或第三方复制、转载、修改或做其他用途，脱离您的预期和控制，您应充分意识到此类风险的存在，任何您不愿被他人获知的信息都不应在</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">发布。</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"> </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">如果相关行为侵犯了您的合法权益，您</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">可以进行</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">投诉，我们将依法进行处理。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">6.4 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">依据本协议约定获得处理违法违规内容或行为的权利，但这一权利不构成</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">的义务或承诺，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">不能保证及时发现违法违规情形或进行相应处理。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">6.5 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">您理解并同意，因业务发展需要，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">保留单方面对机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>号的全部或部分服务内容在任何时候不经任何通知的情况下变更、暂停、限制、终止或撤销的权利，用户需承担此风险。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">6.6 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">您理解并同意，机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">号仅为您提供信息分享、传播及获取的平台，您必须为您的机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>号下的一切行为负责，包括您所发表的任何内容、帐号负责人行为以及由此产生的任何后果。您应对机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>号中的内容自行加以判断，并承担因使用内容而引起的所有风险，包括因对内容的正确性、完整性或适用性的依赖而产生的风险。</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">无法且不会对因前述风险而导致的任何损失或损害承担责任。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">七、法律责任</span><br /><span
					style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt; font-weight: bold"
					>7.1 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">如果</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">发现或收到他人投诉您违反本协议约定的，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、删除部分或全部关注用户、限制或禁止使用部分或全部功能、永久封禁帐号的处罚，并公告处理结果。</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部教育培</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">训中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>也有权依照本协议及专项规则的规定，拒绝再向您提供服务。</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"> </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"
					>如果您发现任何人违反本协议规定或以其他不当的方式使用</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">服务，请立即向</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">投诉，我们将依法进行处理。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">7.2 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">如果</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">发现或收到他人投诉您违反本协议约定的，</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>有权不经通知随时对相关内容进行删除、屏蔽，并视行为情节对违规帐号处以包括但不限于警告、删除部分或全部关注用户、限制或禁止使用部分或全部功能、永久封禁帐号的处罚，并公告处理结果。</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>也有权依照本协议及专项规则的规定，拒绝再向您提供服务。如果您发现任何人违反本协议规定或以其他不当的方式使用</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">服务，请立即向</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">投诉，我们将依法进行处理。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">八</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold"> </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt; font-weight: bold">、其他</span><br /><span
					style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt"
					>8.1 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">您使用机构</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">账</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">号即视为您已阅读并同意受本协议的约束。</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>有权在必要时修改本协议条款。您可以在相关服务页面查阅最新版本的条款。本协议条款变更后，如果您继续使用</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>服务，即视为您已接受修改后的协议。如果您不接受修改后的协议，应当停止使用</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">机构账号</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">服务。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">8.2 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">本协议签订地为中华人民共和国北京市海淀区。</span>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">8.3 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>本协议的成立、生效、履行、解释及纠纷解决，适用中华人民共和国大陆地区法律（不包括冲突法）。</span
				>
			</p>
			<p style="background-color: #ffffff; margin: 7.5pt 0pt 0pt">
				<span style="color: #172b4d; font-family: 'Segoe UI'; font-size: 10.5pt">8.4 </span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">若您与</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt">人力资源和社会保障部社会保障能力建设中心</span
				><span style="color: #172b4d; font-family: 宋体; font-size: 10.5pt"
					>之间发生任何纠纷或争议，首先应友好协商解决；协商不成的，双方同意将纠纷或争议提交本协议签订地有管辖权的人民法院解决。</span
				>
			</p>
			<p style="margin: 0pt; orphans: 0; text-align: justify; widows: 0"><span style="font-family: 等线; font-size: 10.5pt">&#xa0;</span></p>
		</div>
	</el-dialog>
</template>

<script>
export default {
	name: 'institutionInProtocol',
	data() {
		return {
			show: false,
		}
	},
	props: {
		dialogShow: Boolean,
	},
	watch: {
		dialogShow(val) {
			this.show = val
		},
	},
	methods: {
		handleClose() {
			this.$emit('close')
		},
	},
}
</script>

<style lang="stylus" scoped>
@import '../asset/css/institutionInProtocol.styl';
</style>
